<script setup>
const route = useRoute();
const q = ref(route.query.q || "");

function search() {
  if (q.value) {
    navigateTo({ query: { q: q.value } });
  } else {
    navigateTo({ query: { q: null } });
  }
}

// Watch for if q in query disappears and clear the input
watch(
  () => route.query.q,
  (newValue) => {
    q.value = newValue || "";
  }
);
</script>

<template>
  <form class="search-form" @submit.prevent="search">
    <input v-model="q" type="search" placeholder="Search" class="text-mono" />
    <button class="upper">Go</button>
  </form>
</template>

<style lang="scss" scoped>
form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: var(--s);

  input {
    width: 100%;
    flex: 1;
  }
}

// clear safari styling of input type="search"
input[type="search"] {
  -webkit-appearance: none;
  appearance: none;
  background: white;
}
</style>
